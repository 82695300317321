<template>
  <b-card :title="`เเก้ไขข้อมูล ${huayData.name}`">
    <div v-if="!loaded">
      <LottoRate
        :huay-data="huayData"
        :huay-rate="huayRate"
      />

      <LottoCondition
        class="mt-1"
        :huay-data="huayData"
        :huay-condition="huayCondition"
      />

      <b-form-input
        v-model="huayData.externalLink"
        placeholder="ลิงค์ดูผลรางวัล"
        class="mt-1 mb-1"
      />

      <editor
        v-model="huayData.raw"
        :init="config"
      />
      <div class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mt-1"
          @click.prevent="save"
        >
          <feather-icon
            icon="SaveIcon"
            class="mr-50"
          />
          <span class="align-middle">Submit</span>
        </b-button>
      </div>

      <h5>
        ตั้งค่าการเปิดปิดอัตโนมัติ
      </h5>

      <b-form-timepicker
        v-model="huayData.start"
        locale="th"
        class="mt-1"
      />

      <b-form-timepicker
        v-model="huayData.end"
        locale="th"
        class="mt-1"
      />

      <div class="demo-inline-spacing">
        <b-form-checkbox
          v-model="huayData.autoDates[0]"
          class="custom-control-danger"
        >
          อาทิตย์
        </b-form-checkbox>
        <b-form-checkbox
          v-model="huayData.autoDates[1]"
          class="custom-control-yellow"
        >
          จันทร์
        </b-form-checkbox>
        <b-form-checkbox
          v-model="huayData.autoDates[2]"
          class="custom-control-pink"
        >
          อังคาร
        </b-form-checkbox>
        <b-form-checkbox
          v-model="huayData.autoDates[3]"
          class="custom-control-success"
        >
          พุธ
        </b-form-checkbox>
        <b-form-checkbox
          v-model="huayData.autoDates[4]"
          class="custom-control-warning"
        >
          พฤหัส
        </b-form-checkbox>
        <b-form-checkbox
          v-model="huayData.autoDates[5]"
          class="custom-control-info"
        >
          ศุกร์
        </b-form-checkbox>
        <b-form-checkbox
          v-model="huayData.autoDates[6]"
          class="custom-control-primary"
        >
          เสาร์
        </b-form-checkbox>
      </div>

      <div class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mt-1"
          @click.prevent="saveTime"
        >
          <feather-icon
            icon="SaveIcon"
            class="mr-50"
          />
          <span class="align-middle">Submit</span>
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BFormTimepicker, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import lottoService from '@/service/lottoService'
import Editor from '@tinymce/tinymce-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LottoRate from '@/views/components/LottoRate.vue'
import LottoCondition from '@/views/components/LottoCondition.vue'

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    Editor,
    BFormTimepicker,
    BFormCheckbox,
    LottoRate,
    LottoCondition,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      start: '00:00',
      end: '18:00',
      config: {
        height: 600,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount textcolor colorpicker',
        ],
        toolbar:
            'undo redo | formatselect | '
            + ' bold italic backcolor forecolor | alignleft aligncenter '
            + ' alignright alignjustify | bullist numlist outdent indent |'
            + ' removeformat | help',
        content_css: [
          '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
          '//www.tiny.cloud/css/codepen.min.css',
        ],
        images_upload_handler(blobInfo, success) {
          success(
            `data:${blobInfo.blob().type};base64,${blobInfo.base64()}`,
          )
        },
      },
      loaded: true,
      reader: null,
      huayData: {
        name: '',
        externalLink: '',
        raw: '',
        start: '',
        end: '',
        autoDates: [false, false, false, false, false, false, false],
      },
      huayCondition: {},
      huayRate: {},
    }
  },
  async mounted() {
    this.loaded = true
    const { id } = this.$route.query
    if (id) {
      const [res, res2] = await Promise.all([
        lottoService.getAuthen(`huay/id/${id}`),
        lottoService.getAuthen(`rate/condition/${id}`),
      ])
      setTimeout(() => {
        if (res.status === 200) {
          const { autoDates } = { ...res.data }
          delete res.data.autoDates
          this.huayData = { ...res.data, autoDates: this.huayData.autoDates }
          if (autoDates !== null && autoDates !== undefined) {
            this.huayData.autoDates = autoDates
          }

          if (this.huayData.raw == null) {
            this.huayData.raw = ''
          }
        }

        if (res2.status === 200) {
          this.huayCondition = res2.data
          this.huayRate = res2.data
        }

        this.loaded = false
      }, 100)
    }
  },
  methods: {
    confirmText() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        heightAuto: false,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    async save() {
      const { id } = this.$route.query
      if (id) {
        this.$swal({
          dark: true,
          title: 'Are you sure?',
          text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            const res = await lottoService.putAuthen(
              `huay/id/${id}`,
              {
                externalLink: this.huayData.externalLink,
                raw: this.huayData.raw,
              },
            )
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'บันทึกข้อมูสำเร็จ',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: res.data,
                },
              })
            }
          }
        })
      }
    },
    async saveTime() {
      const { id } = this.$route.query
      if (id) {
        this.$swal({
          dark: true,
          title: 'Are you sure?',
          text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              const res = await lottoService.putAuthen(
                `huay/time/${id}?start=${this.huayData.start}&end=${this.huayData.end}`,
                this.huayData.autoDates,
              )
              if (res.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'success',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'บันทึกข้อมูสำเร็จ',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: res.data,
                  },
                })
              }
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.custom-control-pink .custom-control-input:checked ~ .custom-control-label::before ,
.custom-control-pink .custom-control-input:active ~ .custom-control-label::before{
  border-color: hotpink !important;
  background-color: hotpink !important;
  box-shadow: 0 2px 4px 0 rgba(255, 192, 203, 0.4) !important;
}

.custom-control-yellow .custom-control-input:checked ~ .custom-control-label::before ,
.custom-control-yellow .custom-control-input:active ~ .custom-control-label::before{
  border-color: rgba(235, 178, 67, 0.99) !important;
  background-color: rgba(235, 178, 67, 0.99) !important;
  box-shadow: 0 2px 4px 0 rgba(255, 178, 67, 0.4) !important;
}

</style>
